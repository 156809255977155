import React, { ReactInstance, useRef, useState, ReactNode, useEffect } from "react";
import { BookingDataType, PatientDataType, VisitDataType } from "../../utils";
import { newPatientFileNotification, useAppDispatch, useAppSelector, selectLoggedInUser } from "../../appStore";
import PatientFileActionsHeader from "./PatientFileActionsHeader";
import CollapsibleFile from "./CollapsibleFile";
import PrintableFile from "./PrintableFile";
import { AXIOS_CLIENT, baseURL } from "../../appStore/axiosInstance";
import { Id, toast, ToastContainer } from "react-toastify";

export const File = ({
	patientData,
	bookingData,
	visitData,
	showHeader = true,
	demoOnly = false,
	copyRef = false,
}: {
	patientData?: PatientDataType;
	bookingData?: BookingDataType;
	visitData?: VisitDataType;
	showHeader?: boolean;
	demoOnly?: boolean;
	copyRef?: boolean;
}) => {
	const componentRef = useRef<HTMLDivElement | null>(null);
	const [isCollapisble, setIsCollapisble] = useState(true);
	const [imageData, setImageData] = useState<any[]>([]);
	// to prevent images laoading endlessly
	const [imageCount, setImageCount] = useState(0);
	const user = useAppSelector(selectLoggedInUser);
	const isGrader = user?.roles[0] === "FIRST_GRADER"; // graders
	const images = bookingData?.bookingImages;
	const [remidioImages, setRemidioImages] = useState<any[]>([]);

	const dispatch = useAppDispatch();

	let loader: Id;

	useEffect(() => {
		// bookingData?.id && dispatch(getBookingImages(bookingData?.id));
	}, []);

	useEffect(() => {
		if (images?.length && imageCount == 0) {
			loader = toast.loading("Fetching Images", {
				containerId: "images",
				position: "bottom-center",
				autoClose: 10000,
			});
		}
		images?.length && setImageCount((prev) => prev + 1);
	}, [images]);

	useEffect(() => {
		if (imageCount == 2) {
			if (images?.find((item) => item.remidioImgID)) {
				fetch(`${baseURL}patients/remidioPatient/${patientData?.idNumber}`, {
					headers: {
						"Access-Control-Max-Age": "8000",
						Authorization: `Bearer ${user.token}`,
						accept: "*/*",
					},
				})
					.then((res) => {
						return res.json();
					})
					.then((data) => {
						setRemidioImages(data["images"]["fopImages"]["STANDARD"]);
					});
			}

			images?.map(async (item) => {
				if (item.s3URL) {
					fetch(`${baseURL}bookings/downloadBookingImage/${item.s3URL}`, {
						headers: {
							"Access-Control-Max-Age": "8000",
							Authorization: `Bearer ${user.token}`,
							accept: "*/*",
						},
					})
						.then((res) => {
							return res.blob();
						})
						.then((data) => {
							// @ts-ignore
							setImageData((prev) => [...prev, { data, item }]);
						});
				}
			});
		}
	}, [imageCount]);

	useEffect(() => {
		remidioImages.map((item) => {
			if (images?.find((b) => b.remidioImgID === item?.id?.toString())) {
				setImageData((prev) => [
					...prev,
					{
						data: null,
						item: { id: item?.id, remidioImgID: item?.id?.toString(), s3URL: item?.path, title: item?.laterality },
					},
				]);
			}
		});
	}, [remidioImages]);

	useEffect(() => {
		if (images?.length && imageData.length == images.length) {
			toast.dismiss(loader);
			toast.clearWaitingQueue();
		}
	}, [imageData]);

	return patientData ? (
		<section>
			{showHeader ? (
				<PatientFileActionsHeader
					isCollapsible={isCollapisble}
					setCollapsible={setIsCollapisble}
					printRef={componentRef}
					patientData={patientData}
					bookingRef={bookingData?.bookingRef}
					copyRef={copyRef}
					bookingData={bookingData}
				/>
			) : null}

			{isCollapisble ? (
				<CollapsibleFile
					demoData={patientData}
					healthData={bookingData}
					diagnosisData={visitData}
					user={user}
					demoOnly={demoOnly}
					imageData={imageData || []}
				/>
			) : (
				<PrintableFile
					bookingData={bookingData}
					visitData={visitData}
					patientData={patientData}
					user={user}
					printRef={componentRef}
					demoOnly={demoOnly}
					imageData={imageData || []}
				/>
			)}
			<ToastContainer containerId="images" limit={1} />

			{/* This Component is always rendered, but the visibility is hidden. To be used for printing */}
			<PrintableFile
				bookingData={bookingData}
				visitData={visitData}
				patientData={patientData}
				printRef={componentRef}
				user={user}
				demoOnly={demoOnly}
				imageData={imageData || []}
				printOnly
			/>
		</section>
	) : (
		<>
			<h5>This Patient Does not exist</h5>
		</>
	);
};

export default File;

export const FileLine = ({ head, body, className = "" }: { head: string; body?: string; className?: string }) => {
	return body ? (
		<p className={`file-line ${className}`}>
			<span className="file-line-heading">{head}</span>
			<span className="file-line-body">{body}</span>
		</p>
	) : null;
};

export const FileSection = ({ children, heading }: { heading?: string; children?: ReactNode }) => {
	return (
		<div className="file-section">
			<div>
				<h6 className="file-heading ">{heading}</h6>
				{children}
			</div>
		</div>
	);
};

// import React, { ReactInstance, useRef, useState, ReactNode, useEffect } from "react";
// import { BookingDataType, PatientDataType, VisitDataType } from "../../utils";
// import {
// 	newPatientFileNotification,
// 	useAppDispatch,
// 	useAppSelector,
// 	selectLoggedInUser,
// 	getBookingImages,
// 	selectCurrentBookingImages,
// } from "../../appStore";
// import PatientFileActionsHeader from "./PatientFileActionsHeader";
// import CollapsibleFile from "./CollapsibleFile";
// import PrintableFile from "./PrintableFile";
// import { AXIOS_CLIENT, baseURL } from "../../appStore/axiosInstance";
// import { Id, toast, ToastContainer } from "react-toastify";

// export const File = ({
// 	patientData,
// 	bookingData,
// 	visitData,
// 	showHeader = true,
// 	demoOnly = false,
// 	copyRef = false,
// }: {
// 	patientData?: PatientDataType;
// 	bookingData?: BookingDataType;
// 	visitData?: VisitDataType;
// 	showHeader?: boolean;
// 	demoOnly?: boolean;
// 	copyRef?: boolean;
// }) => {
// 	const componentRef = useRef<HTMLDivElement | null>(null);
// 	const [isCollapisble, setIsCollapisble] = useState(true);
// 	const [imageData, setImageData] = useState([]);
// 	// to prevent images laoading endlessly
// 	const [imageCount, setImageCount] = useState(0);
// 	const user = useAppSelector(selectLoggedInUser);
// 	const isGrader = user?.roles[0] === "FIRST_GRADER"; // graders
// 	const images = bookingData?.bookingImages;

// 	const dispatch = useAppDispatch();

// 	let loader: Id;

// 	useEffect(() => {
// 		// bookingData?.id && dispatch(getBookingImages(bookingData?.id));
// 	}, []);

// 	useEffect(() => {
// 		if (images?.length && imageCount == 0) {
// 			loader = toast.loading("Fetching Images", {
// 				containerId: "images",
// 				position: "bottom-center",
// 				autoClose: 10000,
// 			});
// 		}
// 		images?.length && setImageCount((prev) => prev + 1);
// 	}, [images]);

// 	useEffect(() => {
// 		if (imageCount == 2) {
// 			images?.map(async (item) => {
// 				fetch(`${baseURL}bookings/downloadBookingImage/${item.s3URL}`, {
// 					headers: {
// 						"Access-Control-Max-Age": "8000",
// 						Authorization: `Bearer ${user.token}`,
// 						accept: "*/*",
// 					},
// 				})
// 					.then((res) => {
// 						return res.blob();
// 					})
// 					.then((data) => {
// 						// @ts-ignore
// 						setImageData((prev) => [...prev, { data, item }]);
// 					});
// 			});
// 		}
// 	}, [imageCount]);

// 	useEffect(() => {
// 		if (images?.length && imageData.length == images.length) {
// 			toast.dismiss(loader);
// 			toast.clearWaitingQueue();
// 		}
// 	}, [imageData]);

// 	return patientData ? (
// 		<section>
// 			{showHeader ? (
// 				<PatientFileActionsHeader
// 					isCollapsible={isCollapisble}
// 					setCollapsible={setIsCollapisble}
// 					printRef={componentRef}
// 					patientData={patientData}
// 					bookingRef={bookingData?.bookingRef}
// 					copyRef={copyRef}
// 					bookingData={bookingData}
// 				/>
// 			) : null}

// 			{isCollapisble ? (
// 				<CollapsibleFile
// 					demoData={patientData}
// 					healthData={bookingData}
// 					diagnosisData={visitData}
// 					user={user}
// 					demoOnly={demoOnly}
// 					imageData={imageData || []}
// 				/>
// 			) : (
// 				<PrintableFile
// 					bookingData={bookingData}
// 					visitData={visitData}
// 					patientData={patientData}
// 					user={user}
// 					printRef={componentRef}
// 					demoOnly={demoOnly}
// 					imageData={imageData || []}
// 				/>
// 			)}
// 			<ToastContainer containerId="images" limit={1} />
// 		</section>
// 	) : (
// 		<>
// 			<h5>This Patient Does not exist</h5>
// 		</>
// 	);
// };

// export default File;

// export const FileLine = ({ head, body, className = "" }: { head: string; body?: string; className?: string }) => {
// 	return body ? (
// 		<p className={`file-line ${className}`}>
// 			<span className="file-line-heading">{head}</span>
// 			<span className="file-line-body">{body}</span>
// 		</p>
// 	) : null;
// };

// export const FileSection = ({ children, heading }: { heading?: string; children?: ReactNode }) => {
// 	return (
// 		<div className="file-section">
// 			<div>
// 				<h6 className="file-heading ">{heading}</h6>
// 				{children}
// 			</div>
// 		</div>
// 	);
// };
